.box {
  width: 98%;
  height: 5%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;

}

.overlay {
  z-index: 1;
  margin: 1%;
  background: #ffffff;
}

/*
table, th, td {
    padding: 5px;
    border: 1px solid black;
    border-collapse: collapse;
    margin: 0 auto;
  }
  */

table {
  width: 100%;
  border-collapse: collapse;

  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style the table header - blue 307FE2 */
th {
  background-color: #5cb57f;
  color: #ffffff;
  font-weight: bold;
  font-family: "museo-sans-condensed", sans-serif;
  padding: 5px;
  text-align: center;
  border: 1px solid #555555;
}

/* Style the table rows */
tr {
  background-color: #d8d8d8;

  text-align: center;
}

td {
  border: 1px solid #555555;

}

td.highlight {
  background-color: #d8d8d8;
  /* Change this to the desired highlight color */
  font-weight: bold;
  /* Make the text bold, if desired */
  /*color: #ffffff;*/
  /* Change the text color, if needed */
  font-size: large;
}


.imhere {
  background-color: #52ec2c;

}